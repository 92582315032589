import React from 'react'
import { LinkOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal } from 'antd'
import { useModal } from 'src/common/hooks/useModal'
import { useTypedTranslation } from 'src/common/utils'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $getNodeByKey } from 'lexical'
import { $isImageNodeDecorator } from 'src/common/editor/nodes/image/ImageNodeDecorator'
import isEmpty from 'lodash/fp/isEmpty'
import { useFocusElementRef } from 'src/common/hooks'

type FormProps = Partial<{
  url: string
}>

type ImageLinkProps = Readonly<{
  nodeKey: string
  url: string | undefined
}>

function ImageForm({ nodeKey, url, onClose }: ImageLinkProps & { readonly onClose?: () => void }) {
  const focusRef = useFocusElementRef()
  const { t } = useTypedTranslation()
  const [editor] = useLexicalComposerContext()
  const [form] = Form.useForm<FormProps>()

  return (
    <Modal open onCancel={onClose} okText={t('common.add')} onOk={form.submit}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{ url }}
        onFinish={values => {
          editor.update(() => {
            const node = $getNodeByKey(nodeKey)
            if ($isImageNodeDecorator(node)) {
              node.setUrl(values.url)
            }
            return true
          })
          onClose?.()
        }}
      >
        <Form.Item<FormProps>
          label="URL"
          name="url"
          rules={[{ type: 'url', message: t('external_links.edit.url.invalid') }]}
        >
          <Input type="url" ref={focusRef} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export function ImageLink({ nodeKey, url }: ImageLinkProps) {
  const { open, isVisible, close } = useModal()

  return (
    <>
      <Button icon={<LinkOutlined />} size="small" onClick={open} type={!isEmpty(url) ? 'primary' : 'default'} />
      {isVisible && <ImageForm url={url} nodeKey={nodeKey} onClose={close} />}
    </>
  )
}
