import React from 'react'
import { Route, Routes } from 'react-router'
import { ApplicationPrivacyPolicy } from 'src/policies/ApplicationPrivacyPolicy'
import { WebsitePrivacyPolicy } from 'src/policies/WebsitePrivacyPolicy'
import { WebsiteCookiePolicy } from 'src/policies/WebsiteCookiePolicy'
import { SharedContainer } from 'src/modules/shared/SharedContainer'
import { SurveyModule } from 'src/modules/survey/SurveyLazyModule'
import AuthentifiedApp from 'src/app/AuthentifiedApp'
import { LazyLoadFallback } from 'src/app/LazyLoadFallback'
import { ErrorScreen } from 'src/common/ErrorScreen'
import { SearchParamsContextProvider } from 'src/common/providers/SearchParamsContextProvider'

export function Root() {
  return (
    <ErrorScreen>
      <SearchParamsContextProvider>
        <Routes>
          <Route path="policies">
            <Route path="app">
              <Route path="privacy" element={<ApplicationPrivacyPolicy />} />
            </Route>
            <Route path="website">
              <Route path="privacy" element={<WebsitePrivacyPolicy />} />
              <Route path="cookies" element={<WebsiteCookiePolicy />} />
            </Route>
          </Route>
          <Route path="shared/*" element={<SharedContainer />} />
          <Route
            path="surveys/:token"
            element={
              <LazyLoadFallback>
                <SurveyModule />
              </LazyLoadFallback>
            }
          />
          <Route path="*" element={<AuthentifiedApp />} />
        </Routes>
      </SearchParamsContextProvider>
    </ErrorScreen>
  )
}
