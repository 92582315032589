import React, { type ReactNode } from 'react'
import { Tag } from 'antd'
import { FormOutlined } from '@ant-design/icons'

type QuestionTagProps = Readonly<{
  icon?: ReactNode
  children: ReactNode
}>

export function QuestionTag({ icon, children }: QuestionTagProps) {
  return (
    <div className="flex gap-2 justify-between leading-tight items-center">
      <Tag icon={icon ?? <FormOutlined />}>
        <div className={'inline-flex gap-2'}>{children}</div>
      </Tag>
    </div>
  )
}
