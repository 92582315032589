import { colors as traaceColors, images as traaceImages } from 'src/app/theme/traace'
import { colors as tennaxiaColors, images as tenaxiaImages } from 'src/app/theme/tennaxia'
import { OrganizationConfigContext } from 'src/provider'
import { Theme } from 'src/generated/graphql/types'
import { useContext } from 'react'

export function useTheme() {
  const context = useContext(OrganizationConfigContext)
  const tennaxiaThemeEnabled = context?.organization.theme === Theme.Tennaxia
  return {
    colors: tennaxiaThemeEnabled ? tennaxiaColors : traaceColors,
    images: tennaxiaThemeEnabled ? tenaxiaImages : traaceImages
  }
}
