import { DeploymentUnitOutlined } from '@ant-design/icons'
import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import React from 'react'
import { useTypedTranslation } from 'src/common/utils'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { OrganizationLink } from '../OrganizationLink'
import { SUPPLY_CHAIN_ROUTE } from '../routes'
import { usePermissions } from './index'

export function useSupplyChainMenu() {
  const { t } = useTypedTranslation()
  const isSupplyChainEnabled = useOverridableFlag(UnleashFlag.ModuleSupplyChainEnabled)
  const { canReadSuppliers } = usePermissions()

  if (isSupplyChainEnabled && canReadSuppliers) {
    return {
      key: `${SUPPLY_CHAIN_ROUTE}/*`,
      icon: <DeploymentUnitOutlined />,
      label: <OrganizationLink to="/supplyChain/suppliersDashboard">{t('menu.supply_chain')}</OrganizationLink>
    }
  }
  return null
}
