import type { EmissionsItemsCategory } from 'src/modules/settings/dataManagement/categories/_hooks/useOrganizationEmissionsItemsCategories'
import first from 'lodash/fp/first'

export function buildCategoryArrayForPath(
  breadcrumb: EmissionsItemsCategory[] = [],
  categories: EmissionsItemsCategory[]
): EmissionsItemsCategory[] {
  const category = first<EmissionsItemsCategory>(breadcrumb)
  if (!category?.parent?.id) return breadcrumb
  const parentCategory = categories.find(c => c.id === `${category?.parent?.id}`)
  if (!parentCategory?.parent?.id) return breadcrumb
  breadcrumb.unshift(parentCategory!)
  return buildCategoryArrayForPath(breadcrumb, categories)
}
