import {
  ApartmentOutlined,
  DatabaseOutlined,
  FileSearchOutlined,
  SettingOutlined,
  TeamOutlined
} from '@ant-design/icons'
import { useTypedTranslation } from 'src/common/utils'
import { useDataManagementTabsPermissions } from 'src/modules/settings/dataManagement/_hooks/useDataManagementTabsPermissions'
import { useOrganizationTabsPermissions } from 'src/modules/settings/organization/_hooks/useOrganizationTabsPermissions'
import { useTeamTabsPermissions } from 'src/modules/settings/team/_hooks/useTeamTabsPermissions'
import { OrganizationLink } from '../OrganizationLink'
import {
  AUDIT_ROUTE,
  DATA_MANAGEMENT_ROUTE_NEXT,
  ORGANIZATION_ROUTE_NEXT,
  SETTINGS_MENU,
  TEAM_ROUTE_NEXT
} from '../routes'
import { usePermissions } from './index'
import { type GetProp, Menu } from 'antd'
import { LogoutButton } from 'src/authentication/LogoutButton'

export const useSettingsMenu = (): GetProp<typeof Menu, 'items'>[number] | null => {
  const { t } = useTypedTranslation()
  const { canReadAudit, permissionsLoading } = usePermissions()
  const { displayDataManagementModule } = useDataManagementTabsPermissions()
  const { displayOrganizationModule } = useOrganizationTabsPermissions()
  const { displayTeamModule } = useTeamTabsPermissions()

  if (permissionsLoading) return null

  const children: GetProp<typeof Menu, 'items'> = []

  if (displayOrganizationModule) {
    children.push({
      key: `${SETTINGS_MENU} ${ORGANIZATION_ROUTE_NEXT}`,
      icon: <ApartmentOutlined />,
      label: <OrganizationLink to="/settings/organization">{t('menu.organization')}</OrganizationLink>
    })
  }

  if (displayDataManagementModule) {
    children.push({
      key: `${SETTINGS_MENU} ${DATA_MANAGEMENT_ROUTE_NEXT}`,
      icon: <DatabaseOutlined />,
      label: <OrganizationLink to="/settings/data-management">{t('menu.data_management')}</OrganizationLink>
    })
  }

  if (displayTeamModule) {
    children.push({
      key: `${SETTINGS_MENU} ${TEAM_ROUTE_NEXT}`,
      icon: <TeamOutlined />,
      label: <OrganizationLink to="/settings/team">{t('menu.team')}</OrganizationLink>
    })
  }

  if (canReadAudit) {
    children.push({
      key: `${SETTINGS_MENU} ${AUDIT_ROUTE}`,
      icon: <FileSearchOutlined />,
      label: <OrganizationLink to="/settings/audit">{t('menu.audit')}</OrganizationLink>
    })
  }

  children.push({
    key: `${SETTINGS_MENU} logout`,
    label: <LogoutButton />
  })

  return {
    key: SETTINGS_MENU,
    icon: <SettingOutlined />,
    label: t('menu.settings'),
    children
  }
}
