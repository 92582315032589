import type { ColorPalette, DesignSystemColorsType } from 'src/app/theme/type'

export const getDesignColors = ({ primary }: { primary: ColorPalette }): DesignSystemColorsType => ({
  neutral100: '#F7F7F7',
  neutral200: '#E7E7E7',
  neutral300: '#DCDCDC',
  neutral400: '#CBCBCB',
  neutral500: '#B2B2B2',
  neutral600: '#A2A2A2',
  neutral700: '#7E7E7E',
  neutral800: '#626262',
  neutral900: '#4B4B4B',
  neutral1000: '#1E1E1E',
  error100: '#FBEAEA',
  error200: '#F4BFBF',
  error300: '#EEA0A0',
  error400: '#E77574',
  error500: '#E25A59',
  error600: '#DB3130',
  error700: '#C72D2C',
  error800: '#9B2322',
  error900: '#781B1A',
  error1000: '#5C1514',
  primary100: primary[100],
  primary200: primary[200],
  primary300: primary[300],
  primary400: primary[400],
  primary500: primary[500],
  primary600: primary[600],
  primary700: primary[700],
  primary800: primary[800],
  primary900: primary[900],
  primary1000: primary[1000]
})
