import { create } from 'zustand'
import type { IToggle } from '@unleash/proxy-client-react'
import isEmpty from 'lodash/fp/isEmpty'

type FlagState = {
  flags: IToggle[]
  populateFlags: (flags: IToggle[]) => void
  updateFlag: (flagName: string, isEnabled: boolean) => void
}

export const useFlagStore = create<FlagState>()(set => ({
  flags: [],
  populateFlags: (flags: IToggle[]) =>
    set(state => {
      if (!isEmpty(state.flags)) {
        return state
      }
      return { flags }
    }),
  updateFlag: (flagName: string, isEnabled: boolean) =>
    set(state => ({
      flags: state.flags.map(flag => (flag.name === flagName ? { ...flag, enabled: isEnabled } : flag))
    }))
}))
