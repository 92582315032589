import { type QueryResult } from '@apollo/client'
import { createContext } from 'react'
import { type Exact, Language } from 'src/generated/graphql/types'

// Default sorted languages used when no specific provider is used (e.g. not in the OrganizationSortedLanguageProvider)
const SORTED_LANGUAGES = [Language.En, Language.Fr, Language.De, Language.Es]
const DEFAULT_SORTED_LANGUAGES = Object.values(SORTED_LANGUAGES).map((language, index) => ({ language, order: index }))

type SortedLanguageContextType = {
  sortedLanguageCodes: Language[]
  sortedLanguages: { language: Language; order: number }[]
  query: QueryResult<any, Exact<{ [key: string]: never }>> | undefined
}
export const SortedLanguageContext = createContext<SortedLanguageContextType>({
  sortedLanguageCodes: DEFAULT_SORTED_LANGUAGES.map(({ language }) => language),
  sortedLanguages: DEFAULT_SORTED_LANGUAGES,
  query: undefined
})
