import { LogoutOutlined } from '@ant-design/icons'
import { useImpersonateEmail } from 'src/common/hooks/useImpersonateEmail'
import { useApolloClient } from '@apollo/client'
import { Tooltip } from 'antd'
import { useNavigate } from 'react-router'

export function UnImpersonateButton() {
  const { setImpersonateEmail } = useImpersonateEmail()
  const client = useApolloClient()
  const navigate = useNavigate()

  return (
    <div
      onClick={e => {
        e.preventDefault()
        setImpersonateEmail(undefined)
        client.resetStore()
        navigate('/')
      }}
    >
      <Tooltip title="Logout as impersonate user">
        <LogoutOutlined className="hover:opacity-60" />
      </Tooltip>
    </div>
  )
}
