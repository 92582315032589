import { type PropsWithChildren, useMemo } from 'react'
import { DataOriginsContext } from 'src/dataCollection/DataOriginsContext/DataOriginsContext'
import { useDataOriginsQuery } from 'src/generated/graphql/types'
import { useMessageAPI } from 'src/provider/useMessageAPI'

export const DataOriginsContextProvider = ({ children }: PropsWithChildren) => {
  const message = useMessageAPI()
  const { data, loading } = useDataOriginsQuery({ onError: message.apiError })
  const context = useMemo(
    () => ({
      loading,
      dataOrigins: data?.listDataOrigins ?? [],
      defaultDataOrigin: data?.listDataOrigins.find(dataOrigin => dataOrigin.isDefault)
    }),
    [data, loading]
  )

  // Here we render the children only when the data is loaded to make sure the data is always available in the app
  // If we have the same pattern for multiple data we will have a waterfall effect, in this case we should have one
  // provider for all the data or a dedicated component that waits for all providers to be loaded
  return <DataOriginsContext.Provider value={context}>{loading ? null : children}</DataOriginsContext.Provider>
}
