import { Button, Select, Tooltip } from 'antd'
import { useListAuthUsersQuery } from 'src/generated/graphql/types'
import { useImpersonateEmail } from 'src/common/hooks/useImpersonateEmail'
import { LoginOutlined } from '@ant-design/icons'
import debounce from 'lodash/fp/debounce'
import { useState } from 'react'
import { useApolloClient } from '@apollo/client'

export function LogasSelect() {
  const { setImpersonateEmail } = useImpersonateEmail()
  const client = useApolloClient()
  const [selectedEmail, setSelectedEmail] = useState<string>()
  const [emailLike, setEmailLike] = useState<string>()
  const { data, loading } = useListAuthUsersQuery({
    variables: { limit: 20, emailLike },
    context: { adminQuery: true }
  })
  return (
    <div className="flex flex-row justify-between gap-4 mt-4">
      <Select<string>
        allowClear
        loading={loading}
        className="w-full"
        showSearch
        filterOption={false}
        optionLabelProp="label"
        onSearch={debounce(500)(setEmailLike)}
        placeholder="Select a user to impersonate"
        options={data?.listAuthUsers.results.map(user => ({
          label: user.email,
          value: user.email
        }))}
        value={selectedEmail}
        onClear={() => setEmailLike(undefined)}
        onChange={setSelectedEmail}
      />
      <Tooltip title={selectedEmail ? `Log as user ${selectedEmail}` : 'Choose a user to log as'}>
        <Button
          icon={<LoginOutlined />}
          shape="circle"
          onClick={() => {
            if (selectedEmail) {
              setImpersonateEmail(selectedEmail)
              client.resetStore()
            }
          }}
          disabled={!selectedEmail}
        />
      </Tooltip>
    </div>
  )
}
