import { useAuth0 } from '@auth0/auth0-react'
import { Button } from 'antd'
import { useTypedTranslation } from 'src/common/utils'

export const LogoutButton = () => {
  const { t } = useTypedTranslation()
  const { logout } = useAuth0()

  return (
    <Button
      type="primary"
      className="border border-inherit"
      onClick={async () => {
        await logout({
          logoutParams: {
            returnTo: window.location.origin
          }
        })
      }}
    >
      {t('login.logout_button_title')}
    </Button>
  )
}
