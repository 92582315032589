import type { SetURLSearchParams } from 'react-router'
import { createContext } from 'react'

type SearchParamsContextType = {
  searchParams: URLSearchParams
  setSearchParams?: SetURLSearchParams
}

export const SearchParamsContext = createContext<SearchParamsContextType>({
  searchParams: new URLSearchParams()
})
