import { useStateWithSessionStorage } from 'src/common/hooks'
import { useLocation, useSearchParams } from 'react-router'
import isString from 'lodash/fp/isString'
import { SortOrder } from 'src/type'
import { useEffect } from 'react'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'

const DEFAULT_LIMIT = 10
const DEFAULT_OFFSET = 0

export type BaseFilterParams<F, S extends string = ''> = {
  limit: number
  offset: number
  order: SortOrder
  sort?: S
  filters: F
}

type PaginationWithFiltersParams<F> = {
  key?: string
  defaultLimit?: number
  defaultFilters: F
  useSession?: boolean
  useUrl?: boolean
}

export type PaginationWithFiltersReturn<F, S extends string = ''> = BaseFilterParams<F, S> & {
  currentPage?: number
  handleSortChange: (sortOrder: BaseFilterParams<F, S>['order'], sortingKey: S) => void
  resetFilters: () => void
  onChange: (newCurrent: number, newPageSize: number) => void
  handleFilterChange: (filters?: Partial<F>) => void
  checkCurrentPage: (totalCount?: number) => void
  setCurrentPage: (pageNumber: number) => void
}

export const getUrlParams = <F extends Record<string, string | string[] | null>, S extends string = ''>(
  newParams: BaseFilterParams<F, S>,
  prevParams = new URLSearchParams(),
  defaultParams?: BaseFilterParams<F, S>
) => {
  const setSearchParam = (key: Exclude<keyof BaseFilterParams<F, S>, 'filters'>) => {
    newParams[key] && newParams[key] !== defaultParams?.[key]
      ? prevParams.set(key, String(newParams[key]))
      : prevParams.delete(key)
  }
  setSearchParam('limit')
  setSearchParam('offset')
  setSearchParam('order')
  setSearchParam('sort')
  return getUrlParamsWithFilters(newParams.filters, prevParams)
}

export const getUrlParamsWithFilters = (
  filters?: Record<string, string | string[] | null | undefined>,
  prevParams = new URLSearchParams()
) => {
  Object.entries(filters || {}).forEach(([filterKey, value]) => {
    prevParams.delete(filterKey)
    prevParams.delete(`${filterKey}[]`)
    if (Array.isArray(value)) {
      if (value.length === 0) prevParams.set(`${filterKey}[]`, '')
      else value.forEach(item => prevParams.append(`${filterKey}[]`, item))
    } else if (isString(value) && !isEmpty(value)) {
      prevParams.set(filterKey, value)
    }
  })
  return prevParams
}

const reservedParams = ['offset', 'limit', 'order', 'sort']
const getParamsFromUrl = <F extends Record<string, string | string[] | null>, S extends string = ''>(
  searchParams: URLSearchParams,
  defaultParams: BaseFilterParams<F, S>
) => {
  if (searchParams.size > 0) {
    const newParams: BaseFilterParams<F, S> = {
      offset: parseInt(searchParams.get('offset') ?? String(defaultParams.offset), 10),
      limit: parseInt(searchParams.get('limit') ?? String(defaultParams.limit), 10),
      order: (searchParams.get('order') ?? defaultParams.order) as SortOrder,
      sort: (searchParams.get('sort') ?? defaultParams.sort) as S | undefined,
      filters: { ...defaultParams.filters }
    }
    searchParams.forEach((paramValue, paramKey) => {
      if (!reservedParams.includes(paramKey)) {
        // @ts-expect-error force set on filters of type T
        newParams.filters[paramKey.replace('[]', '')] = paramKey.endsWith('[]')
          ? searchParams.getAll(paramKey).filter(item => item !== '')
          : searchParams.get(paramKey)
      }
    })
    return newParams
  }
  return undefined
}

export const usePaginationWithFilters = <F extends Record<string, string | string[] | null>, S extends string = ''>({
  key = 'filters',
  defaultLimit = DEFAULT_LIMIT,
  defaultFilters,
  useSession = true,
  useUrl = true
}: PaginationWithFiltersParams<F>): PaginationWithFiltersReturn<F, S> => {
  const { pathname } = useLocation()

  const defaultParams: BaseFilterParams<F, S> = {
    offset: DEFAULT_OFFSET,
    limit: defaultLimit,
    sort: undefined,
    order: SortOrder.ASC,
    filters: defaultFilters
  }

  const [params, setSessionParams] = useStateWithSessionStorage<BaseFilterParams<F, S>>(
    useSession ? `${pathname}/${key}` : undefined,
    defaultParams
  )
  const [searchParams, setSearchParams] = useSearchParams()

  const urlParams = useUrl ? getParamsFromUrl(searchParams, defaultParams) : undefined

  const currentParams = urlParams ?? params ?? defaultParams
  const limit = currentParams.limit
  const offset = currentParams.offset

  const currentPage = offset / limit + 1
  const getOffset = (pageNumber: number) => Math.round((pageNumber - 1) * limit)

  const setParams = (newParams: BaseFilterParams<F, S>) => {
    setSessionParams(newParams)
    if (useUrl) setSearchParams(getUrlParams(newParams, undefined, defaultParams), { replace: true })
  }

  useEffect(() => {
    if (useUrl && !urlParams && params && !isEqual(params, defaultParams)) {
      setSearchParams(getUrlParams(params, undefined, defaultParams), { replace: true })
    } else if (urlParams && !isEqual(urlParams, params)) {
      setParams(urlParams)
    }
  }, [])

  return {
    ...currentParams,
    currentPage,
    handleSortChange: (sortOrder: BaseFilterParams<F, S>['order'], sortingKey: S) => {
      setParams({ ...currentParams, order: sortOrder, sort: sortingKey, offset: 0 })
    },
    onChange: (newCurrent: number, newPageSize: number) => {
      if (limit !== newPageSize) {
        setParams({ ...currentParams, limit: newPageSize, offset: 0 })
      } else {
        setParams({ ...currentParams, offset: getOffset(newCurrent) })
      }
    },
    handleFilterChange: (filters?: Partial<F>) => {
      setParams({ ...currentParams, filters: { ...currentParams.filters, ...filters }, offset: 0 })
    },
    resetFilters: () => {
      const resetFitlers = { ...currentParams, filters: defaultFilters, offset: 0 }
      setSessionParams(resetFitlers)
      if (useUrl) {
        setSearchParams(getUrlParams(resetFitlers, new URLSearchParams()), { replace: true })
      }
    },
    checkCurrentPage: (totalCount?: number) => {
      if (totalCount && currentPage && totalCount <= limit * (currentPage - 1)) {
        const newCurrentPage = Math.trunc(Math.max((totalCount + limit - 1) / limit, 1))
        if (newCurrentPage !== currentPage) {
          setParams({ ...currentParams, offset: getOffset(newCurrentPage) })
        }
      }
    },
    setCurrentPage: (pageNumber: number) => {
      setParams({ ...currentParams, offset: Math.round((pageNumber - 1) * limit) })
    }
  }
}

export type Pagination = Omit<
  PaginationWithFiltersReturn<Record<string, string | string[] | null>, ''>,
  'filters' | 'order' | 'handleSortChange'
>

export const usePagination = <S extends string = ''>(
  params?: Omit<PaginationWithFiltersParams<Record<string, string | string[] | null>>, 'defaultFilters'>
) => usePaginationWithFilters<Record<string, string | string[] | null>, S>({ ...params, defaultFilters: {} })

/**
 * Pagination hook that does not store the pagination state in the URL or session storage (useful for modals)
 */
export const useModalPagination = (
  params?: Omit<
    PaginationWithFiltersParams<Record<string, string | string[] | null>>,
    'defaultFilters' | 'useSession' | 'useUrl'
  >
): Pagination => usePaginationWithFilters({ ...params, useSession: false, useUrl: false, defaultFilters: {} })
