import React, { createRef, useEffect, useState } from 'react'
import { Button, Form, Input, type InputRef, Modal } from 'antd'
import { LinkOutlined } from '@ant-design/icons'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link'
import { $getSelection, $isRangeSelection } from 'lexical'

type FormValues = Partial<{
  url: string
}>

export function Link() {
  const [editor] = useLexicalComposerContext()
  const [isLink, setIsLink] = useState(false)
  const [open, setOpen] = useState(false)
  const inputRef = createRef<InputRef>()
  const [form] = Form.useForm<FormValues>()

  const onClose = () => {
    setOpen(false)
    setIsLink(false)
    form.resetFields()
    setTimeout(() => {
      editor.focus()
    }, 200)
  }

  useEffect(() => {
    if (open) {
      inputRef.current?.focus()
      editor.getEditorState().read(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          const parentNode = selection.anchor.getNode().getParent()
          if ($isLinkNode(parentNode)) {
            setIsLink(true)
            form.setFieldValue('url', parentNode.getURL())
          }
        }
      })
    }
  }, [open])
  return (
    <>
      <Button
        type="text"
        size="small"
        icon={<LinkOutlined />}
        onClick={() => {
          setOpen(true)
        }}
      />
      <Modal
        title="Ajouter un lien"
        open={open}
        onCancel={onClose}
        footer={
          <>
            {isLink && (
              <Button
                onClick={() => {
                  editor.update(() => {
                    editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
                    setOpen(false)
                    form.resetFields()
                  })
                }}
              >
                Supprimer
              </Button>
            )}
            <Button type="primary" onClick={form.submit}>
              {isLink ? 'Mettre à jour' : 'Ajouter'}
            </Button>
          </>
        }
      >
        <Form
          form={form}
          onFinish={values => {
            editor.update(() => {
              editor.dispatchCommand(TOGGLE_LINK_COMMAND, values.url!)
              onClose()
            })
          }}
          initialValues={{ url: '' }}
        >
          <Form.Item<FormValues>
            name="url"
            rules={[
              { required: true, message: 'Veuillez saisir une URL' },
              { type: 'url', message: 'url non valide' }
            ]}
          >
            <Input ref={inputRef} placeholder={'https://'} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
