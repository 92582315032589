import { LeftOutlined, ReadOutlined, RightOutlined, UserOutlined } from '@ant-design/icons'
import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import { type GetProp, Layout, Menu, type MenuProps, Tooltip } from 'antd'
import isEmpty from 'lodash/fp/isEmpty'
import last from 'lodash/fp/last'
import { Link, Outlet } from 'react-router'
import Profile from 'src/authentication/Profile'
import { useTypedTranslation } from 'src/common/utils'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import {
  useAnalyzeMenu,
  useMeasureMenu,
  useMyTasksMenu,
  useNavigationState,
  useReduceMenu,
  useSettingsMenu,
  useSupplyChainMenu
} from 'src/navigation/_hooks'
import useAdminMenu from 'src/navigation/_hooks/useAdminMenu'
import { USER_ROUTE } from 'src/navigation/routes'
import { useLCAMenu } from './_hooks/useLCAMenu'
import { useReportingMenu } from 'src/navigation/_hooks/useReportingMenu'
import { useCustomLinkMenu } from 'src/navigation/_hooks/useCustomLinkMenu'
import { useTheme } from 'src/app/theme/useTheme'
import { useHelpLink } from 'src/navigation/_hooks/useHelpLink'
import { useBoolean } from 'usehooks-ts'
import { useState } from 'react'
import { useImpersonateEmail } from 'src/common/hooks/useImpersonateEmail'
import classNames from 'classnames'

const HELP_KEY = 'help'

function NavMenu() {
  const { t } = useTypedTranslation()
  const { value: isCollapsed, toggle: toggleIsCollapsed } = useBoolean(false)
  const [openKeys, setOpenKeys] = useState<string[]>([])
  const isReduceModuleEnabled = useOverridableFlag(UnleashFlag.ModuleReduceEnabled)
  const myTasksMenu = useMyTasksMenu()
  const mesureMenu = useMeasureMenu(openKeys)
  const reduceMenu = useReduceMenu({ isReduceModuleEnabled: isReduceModuleEnabled })
  const settingsMenuNext = useSettingsMenu()
  const supplyChainMenu = useSupplyChainMenu()
  const adminMenu = useAdminMenu()
  const analyzeMenu = useAnalyzeMenu()
  const lcaMenu = useLCAMenu()
  const reportingMenu = useReportingMenu()
  const customLinkMenu = useCustomLinkMenu()
  const openHelp = useHelpLink('https://help.traace.co')
  const { images } = useTheme()
  const { isImpersonateUser } = useImpersonateEmail()

  const upMenuItems: GetProp<typeof Menu, 'items'> = [
    myTasksMenu,
    mesureMenu,
    analyzeMenu,
    reduceMenu,
    supplyChainMenu,
    lcaMenu,
    reportingMenu,
    customLinkMenu
  ]

  const downMenuItems: GetProp<typeof Menu, 'items'> = [
    {
      key: HELP_KEY,
      icon: <ReadOutlined />,
      label: <>{t('menu.docs')}</>,
      onClick: openHelp
    },
    {
      key: `${USER_ROUTE}/*`,
      icon: <UserOutlined />,
      label: <Profile />
    },
    settingsMenuNext,
    adminMenu
  ]

  const { selectedKeys, setSelectedKeys } = useNavigationState({ upMenuItems, downMenuItems, setOpenKeys })

  const onOpenChange = (keys: string[]) => {
    const openedKeys = isEmpty(keys) ? [] : [last(keys) as string]
    setOpenKeys(openedKeys)
    setSelectedKeys(openedKeys)
  }
  const onMenuClick: GetProp<MenuProps, 'onClick'> = item => {
    if (item.key !== HELP_KEY) {
      setOpenKeys(item.keyPath)
      setSelectedKeys([item.key])
    }
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout.Sider
        breakpoint="lg"
        collapsedWidth="60"
        collapsible
        collapsed={isCollapsed}
        onCollapse={toggleIsCollapsed}
        width={250}
        style={{ width: 'inherit' }}
        className="z-10 bg-darkgreen-base min-h-[100vh]"
        trigger={
          <Tooltip title={isCollapsed ? t('menu.expand') : t('menu.collapse')}>
            <div className="bg-darkgreen-base">
              <div className="bg-white/10">{isCollapsed ? <RightOutlined /> : <LeftOutlined />}</div>
            </div>
          </Tooltip>
        }
      >
        <div
          className="flex flex-col fixed top-0 bottom-12 overflow-y-auto overflow-x-hidden"
          style={{ width: 'inherit' }}
        >
          <div className={classNames('text-center py-10', { 'bg-red-400': isImpersonateUser })}>
            <a href="/" className="">
              {isCollapsed ? (
                <img src={images.compactLogoUrl} className="m-auto hidden lg:block" alt="" width="20px" />
              ) : (
                <img src={images.logoUrl} className="m-auto hidden lg:block" alt="" width="112px" height="20px" />
              )}
              <img src={images.compactLogoUrl} className="m-auto lg:hidden" alt="" width="20px" />
            </a>
          </div>
          <div className="flex flex-col h-full justify-between">
            <div>
              <Menu
                mode="inline"
                selectable={false}
                className="border-r-0"
                items={upMenuItems}
                openKeys={openKeys}
                selectedKeys={selectedKeys}
                onOpenChange={onOpenChange}
                onClick={onMenuClick}
              />
            </div>
            <div>
              <Menu
                mode="inline"
                selectable={false}
                className="border-r-0"
                items={downMenuItems}
                openKeys={openKeys}
                selectedKeys={selectedKeys}
                onOpenChange={onOpenChange}
                onClick={onMenuClick}
              />
              {!isCollapsed && (
                <div className="text-center flex-shrink w-full pb-4">
                  <Link
                    target="_blank"
                    className="text-xs text-white text-opacity-50 hover:text-opacity-100 "
                    to="/policies/app/privacy"
                  >
                    {t('menu.privacy')}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout.Sider>
      <Layout>
        <Layout.Content>
          <div className="w-full">
            <Outlet />
          </div>
        </Layout.Content>
      </Layout>
    </Layout>
  )
}

export default NavMenu
