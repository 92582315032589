import { $createParagraphNode, $createTextNode, $getRoot, type ElementFormatType, ElementNode } from 'lexical'
import isString from 'lodash/fp/isString'
import isEmpty from 'lodash/fp/isEmpty'
import { $createHeadingNode, type HeadingTagType } from '@lexical/rich-text'
import { $createLinkNode } from '@lexical/link'
import { $createImageNodeDecorator } from 'src/common/editor/nodes/image'

type QuillLine = {
  insert: string | { image: string }
  attributes?: {
    width?: string
    style?: string
    underline?: boolean
    bold?: boolean
    italic?: boolean
    strike?: boolean
    color?: string
    link?: string
    align?: 'left' | 'center' | 'right'
    header?: 1 | 2
  }
}

type QuillIndex = {
  type: string
  align?: 'left' | 'center' | 'right'
  value: string | number | undefined
  startIndex: number
  endIndex: number
}
type QuillSelector = QuillIndex & { children: QuillLine[] }

type QuillObject = {
  ops: QuillLine[]
}

type ConvertQuillToLexicaProps = {
  data?: QuillObject
}

function getComputedData(data: ConvertQuillToLexicaProps['data']) {
  const resultData = []
  let stream = []
  for (const line of data?.ops ?? []) {
    if (isString(line.insert)) {
      stream.push(line)
    } else {
      resultData.push(stream)
      stream = []
      resultData.push(line)
    }
  }
  resultData.push(stream)
  return resultData
}

function getStartIndex(endIndex: number, line: QuillLine[]): number {
  let startIndex: number | undefined = undefined
  let i = endIndex - 1
  while (startIndex === undefined && i >= 0) {
    if (isString(line[i]?.insert) && (line[i]?.insert as string).includes('\n')) {
      startIndex = i
    }
    i--
  }
  if (startIndex === undefined) {
    return 0
  }
  if (isString(line[startIndex]?.insert) && !(line[startIndex]?.insert as string).endsWith('\n')) {
    return startIndex
  }
  return startIndex + 1
}

function fillParagraphIndexes(indexes: QuillIndex[], length: number): QuillIndex[] {
  let result: QuillIndex[] = [...indexes]
  if (isEmpty(indexes)) return [{ type: 'paragraph', value: undefined, startIndex: 0, endIndex: length }]
  indexes.forEach((quillIndex, index) => {
    if (quillIndex.startIndex !== 0 && index === 0) {
      result = [
        {
          type: 'paragraph',
          value: undefined,
          startIndex: 0,
          endIndex: indexes[index]?.startIndex || length
        },
        ...indexes
      ]
    } else if (indexes[index] && indexes[index].endIndex !== (indexes[index + 1]?.startIndex || length)) {
      result.push({
        type: 'paragraph',
        value: undefined,
        startIndex: indexes[index].endIndex,
        endIndex: indexes[index + 1]?.startIndex || length
      })
    }
  })
  return result.sort((a, b) => a.startIndex - b.startIndex)
}

function computeAllIndexes(data: any[]): QuillSelector[] {
  const result: QuillSelector[] = []
  for (const line of data) {
    if (Array.isArray(line)) {
      const indexes: QuillIndex[] = []
      const newLine = []
      line.forEach((item: QuillLine, index) => {
        if (['center', 'right'].includes(item.attributes?.align || '') && item.attributes?.header) {
          indexes.push({
            type: `header`,
            align: item.attributes.align,
            value: item.attributes.header,
            startIndex: getStartIndex(index, line),
            endIndex: index + 1
          })
        } else if (['center', 'right'].includes(item.attributes?.align || '')) {
          indexes.push({
            type: 'align',
            value: item.attributes?.align,
            startIndex: getStartIndex(index, line),
            endIndex: index + 1
          })
        } else if (item.attributes?.header) {
          indexes.push({
            type: `header`,
            value: item.attributes.header,
            startIndex: getStartIndex(index, line),
            endIndex: index + 1
          })
        }
      })
      for (const item of fillParagraphIndexes(indexes, line.length)) {
        newLine.push({ ...item, children: line.slice(item.startIndex, item.endIndex) })
      }
      result.push(...newLine)
    } else {
      result.push({ type: 'image', value: undefined, endIndex: 0, startIndex: 0, children: [line] })
    }
  }
  return result
}

const COLORS: { [key: string]: string } = {
  blue: '#06c',
  green: '#008a00',
  red: '#e60000',
  orange: '#f90',
  violet: '#a64dff'
}

const styleTextNode = (textNode: any, attributes: QuillLine['attributes']) => {
  const style = []
  if (attributes?.bold) {
    textNode.setFormat('bold')
    style.push('font-weight: bold;')
  }
  if (attributes?.italic) {
    textNode.setFormat('italic')
    style.push('font-style: italic;')
  }
  if (attributes?.strike) {
    textNode.setFormat('strikethrough')
    style.push('text-decoration: line-through;')
  }
  if (attributes?.underline) {
    textNode.setFormat('underline')
    style.push('text-decoration: underline;')
  }
  if (attributes?.color) {
    style.push(`color: ${COLORS[attributes.color]};`)
  }
  if (!isEmpty(style)) {
    textNode.setStyle(style.join(' '))
  }
}

const buildChildrenNodes = (children: QuillLine[], parentNode: ElementNode) => {
  for (const line of children) {
    if (line.attributes?.link) {
      const linkNode = $createLinkNode(line.attributes.link)
      const textNode = $createTextNode(line.insert as string)
      styleTextNode(textNode, line.attributes)
      linkNode.append(textNode)
      parentNode.append(linkNode)
    } else {
      const textNode = $createTextNode(line.insert as string)
      styleTextNode(textNode, line.attributes)
      parentNode.append(textNode)
    }
  }
}

export function useConvertQuillToLexical({ data }: ConvertQuillToLexicaProps) {
  return {
    getInitialValue: () => {
      const root = $getRoot()
      for (const block of computeAllIndexes(getComputedData(data))) {
        if (block.type === 'header') {
          const headingNode = $createHeadingNode(`h${block.value}` as HeadingTagType)
          if (block.align) {
            headingNode.setFormat(block.align as ElementFormatType)
          }
          buildChildrenNodes(block.children, headingNode)
          root.append(headingNode)
        }
        if (block.type === 'align') {
          const paragraphNode = $createParagraphNode()
          paragraphNode.setFormat(block.value as ElementFormatType)
          buildChildrenNodes(block.children, paragraphNode)
          root.append(paragraphNode)
        }
        if (block.type === 'paragraph') {
          const paragraphNode = $createParagraphNode()
          buildChildrenNodes(block.children, paragraphNode)
          root.append(paragraphNode)
        }
        if (block.type === 'image') {
          const paragraphNode = $createParagraphNode()
          for (const line of block.children) {
            if (!isString(line.insert)) {
              const align = (function () {
                switch (true) {
                  case line.attributes?.style?.includes('margin: auto'):
                    return 'center'
                  case line.attributes?.style?.includes('float: right'):
                    return 'right'
                  default:
                    return 'left'
                }
              })()
              paragraphNode.append(
                $createImageNodeDecorator({
                  src: line.insert.image,
                  width: line.attributes?.width ? Number.parseInt(line.attributes.width, 10) : undefined,
                  height: undefined,
                  align
                })
              )
            }
          }
          root.append(paragraphNode)
        }
      }
    }
  }
}
