import { useOrganizationSortedLanguages } from 'src/modules/settings/organization/LanguagesSettings/_hooks/useOrganizationSortedLanguages'
import { SortedLanguageContext } from 'src/provider/OrganizationSortedLanguageProvider/SortedLanguageContext'
import { type PropsWithChildren, useMemo } from 'react'

export const OrganizationSortedLanguageProvider = ({ children }: PropsWithChildren) => {
  const { sortedLanguageCodes, sortedLanguages, ...query } = useOrganizationSortedLanguages()
  const value = useMemo(
    () => ({ sortedLanguageCodes, sortedLanguages, query }),
    [sortedLanguageCodes, sortedLanguages, query]
  )
  return <SortedLanguageContext.Provider value={value}>{children}</SortedLanguageContext.Provider>
}
