import { FileDoneOutlined } from '@ant-design/icons'
import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import { useTypedTranslation } from 'src/common/utils'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { OrganizationLink } from 'src/navigation/OrganizationLink'
import { KNOWLEDGE_BASE_ROUTE, MATERIALITY_ASSESSMENT_ROUTE, REPORTING_MENU } from 'src/navigation/routes'
import { type GetProp, Menu } from 'antd'

export function useReportingMenu() {
  const isReportingModuleEnabled = useOverridableFlag(UnleashFlag.ReportingModule)
  const isSalesEnablementEnabled = useOverridableFlag(UnleashFlag.SalesEnablement)
  const { t } = useTypedTranslation()
  const children: GetProp<typeof Menu, 'items'> = []

  if (!isReportingModuleEnabled) {
    return null
  }

  if (isSalesEnablementEnabled) {
    children.push(
      {
        key: `${REPORTING_MENU} ${KNOWLEDGE_BASE_ROUTE}`,
        icon: <FileDoneOutlined />,
        label: <OrganizationLink to="/reporting">{t('menu.reporting_knowledgebase')}</OrganizationLink>
      },
      {
        key: `${REPORTING_MENU} ${MATERIALITY_ASSESSMENT_ROUTE}`,
        icon: <FileDoneOutlined />,
        label: (
          <OrganizationLink to="/reporting/materialityAssessment">{t('menu.materiality_assessment')}</OrganizationLink>
        )
      }
    )
  }

  if (isSalesEnablementEnabled)
    return {
      key: REPORTING_MENU,
      icon: <FileDoneOutlined />,
      label: t('menu.reporting'),
      children: children
    }

  return {
    key: REPORTING_MENU,
    icon: <FileDoneOutlined />,
    label: <OrganizationLink to="/reporting">{t('menu.reporting')}</OrganizationLink>
  }
}
