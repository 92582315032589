import { Modal, Switch } from 'antd'
import { useFlagStore } from 'src/services/unleash/FlagStore'

type UnleashFlagsOverrideModalProps = Readonly<{ open: boolean; onCancel: () => void }>

export const UnleashFlagsOverrideModal = ({ open, onCancel }: UnleashFlagsOverrideModalProps) => {
  const flagSessionOverride = useFlagStore(state => state.flags)
  const updateFlag = useFlagStore(state => state.updateFlag)
  return (
    <Modal title="Unleash Flags Override" open={open} onCancel={onCancel} footer={null}>
      {flagSessionOverride.map(flag => {
        return (
          <div key={flag.name}>
            <Switch
              className="m-2"
              checked={flag.enabled}
              onChange={val => {
                updateFlag(flag.name, val)
              }}
            />
            {flag.name}
          </div>
        )
      })}
    </Modal>
  )
}
