export function calculateChildrenDepth(node: { children?: any[] | undefined }): number {
  if (!node.children || node.children.length === 0) {
    return 1
  } else {
    let maxDepth = 0
    for (const child of node.children) {
      const childDepth = calculateChildrenDepth(child)
      if (childDepth > maxDepth) {
        maxDepth = childDepth
      }
    }
    return 1 + maxDepth
  }
}
