import * as Apollo from '@apollo/client'
import { calculateChildrenDepth } from 'src/common/utils/caculateChildrenDepth'
import {
  type CurrentOrganizationEmissionsItemsCategoriesQuery,
  type CurrentOrganizationEmissionsItemsCategoriesQueryVariables,
  useCurrentOrganizationEmissionsItemsCategoriesQuery
} from 'src/generated/graphql/types'

export type ReportingCategory = {
  id: string
  scope: number
  name: string
  nameEN: string
  nameFR: string
}

export type EmissionsItemsCategory = {
  id: string
  name: string
  nameEN?: string
  nameFR?: string
  description?: string | null
  iconName: string
  color: string
  reportingCategory?: ReportingCategory | null
  parent?: EmissionsItemsCategory | null
  children: EmissionsItemsCategory[]
}

export const useCurrentOrganizationEmissionsItemsCategories = (
  baseOptions?: Apollo.QueryHookOptions<
    CurrentOrganizationEmissionsItemsCategoriesQuery,
    CurrentOrganizationEmissionsItemsCategoriesQueryVariables
  >
) => {
  const { data, ...rest } = useCurrentOrganizationEmissionsItemsCategoriesQuery(baseOptions)

  const hierarchy = data ? createHierarchy(data) : undefined
  return {
    ...rest,
    data: hierarchy,
    categoryTreeDepth: hierarchy ? calculateChildrenDepth(hierarchy.rootEmissionItemsCategory) - 1 : 0
  }
}

function createHierarchy(data: CurrentOrganizationEmissionsItemsCategoriesQuery): {
  rootEmissionItemsCategory: EmissionsItemsCategory
  emissionItemsCategories: EmissionsItemsCategory[]
} {
  const emissionItemsCategories: EmissionsItemsCategory[] = data.organizationEmissionsItemsCategories.map(category => ({
    ...category,
    children: []
  })) as EmissionsItemsCategory[]

  let rootEmissionItemsCategory: EmissionsItemsCategory | undefined = undefined

  emissionItemsCategories.forEach(emissionItemCategory => {
    emissionItemCategory.parent = emissionItemsCategories.find(c => c.id === emissionItemCategory.parent?.id)

    if (!emissionItemCategory.parent) {
      rootEmissionItemsCategory = emissionItemCategory
    } else {
      emissionItemCategory.parent.children = [...emissionItemCategory.parent.children, emissionItemCategory]
    }
  })

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!rootEmissionItemsCategory) throw new Error('Root category not found')

  return {
    rootEmissionItemsCategory,
    emissionItemsCategories
  }
}
