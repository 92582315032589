import { FlagProvider, useFlags, useFlagsStatus, useUnleashContext } from '@unleash/proxy-client-react'
import React, { type PropsWithChildren, useEffect } from 'react'
import { useOrganizationConfig } from 'src/provider'
import { unleashConfig } from 'src/services/unleash/unleashConfig'
import { CenteredLoader } from 'src/common/utils'
import { useFlagStore } from 'src/services/unleash/FlagStore'
import type { UserInfos } from 'src/models/UserInfos'
import type { CurrentUserConfigQuery } from 'src/generated/graphql/types'

function getUnleashContext(
  currentUser: UserInfos,
  organization: CurrentUserConfigQuery['currentUser']['organization']
) {
  return {
    userId: currentUser.id,
    properties: {
      organizationId: organization.id,
      organizationName: organization.name,
      email: currentUser.email,
      branch: import.meta.env.VITE_ENV ?? 'development'
    }
  }
}

function WaitUntilFlagsAreReady({ children }: PropsWithChildren) {
  const { flagsReady } = useFlagsStatus()
  const flags = useFlags()
  const { organization, currentUser } = useOrganizationConfig()
  const updateContext = useUnleashContext()
  const populateFlags = useFlagStore(state => state.populateFlags)

  useEffect(() => {
    updateContext(getUnleashContext(currentUser, organization))
  }, [currentUser.id, organization.id])

  useEffect(() => {
    if (flagsReady) {
      populateFlags(flags)
    }
  }, [flagsReady, flags, organization])

  if (!flagsReady) {
    return <CenteredLoader />
  }
  return children
}

export function UnleashProvider({ children }: PropsWithChildren) {
  const { currentUser, organization } = useOrganizationConfig()

  return (
    <FlagProvider
      config={{
        ...unleashConfig,
        context: getUnleashContext(currentUser, organization)
      }}
    >
      <WaitUntilFlagsAreReady>{children}</WaitUntilFlagsAreReady>
    </FlagProvider>
  )
}
