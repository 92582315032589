import type { IConfig } from 'unleash-proxy-client'

const unleashRefreshInterval = import.meta.env.VITE_UNLEASH_REFRESH_INTERVAL
  ? parseInt(import.meta.env.VITE_UNLEASH_REFRESH_INTERVAL)
  : 30

export const unleashConfig: IConfig = {
  url: import.meta.env.VITE_UNLEASH_URL ?? '',
  clientKey: import.meta.env.VITE_UNLEASH_TOKEN ?? '',
  refreshInterval: unleashRefreshInterval, // How often (in seconds) the client should poll the server for updates
  appName: import.meta.env.VITE_UNLEASH_APP_NAME ?? 'traace-frontend',
  environment: import.meta.env.VITE_ENV ?? 'development'
}
