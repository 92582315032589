import { Layout, Spin } from 'antd'
import { type PropsWithChildren } from 'react'

type ContainerProps = PropsWithChildren &
  Readonly<{
    className?: string
    loading?: boolean
  }>

export default function Container({ className, children, loading }: ContainerProps) {
  return (
    <Layout className="px-4 min-h-full mb-10">
      <Layout.Content
        className={`site-layout-background p-6 ${className ? className : ''}`}
        style={{
          margin: 0,
          minHeight: 280
        }}
      >
        {loading ? (
          <div className="flex items-center justify-center h-[280px]">
            <Spin />
          </div>
        ) : (
          children
        )}
      </Layout.Content>
    </Layout>
  )
}
