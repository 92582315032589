import { AimOutlined, BulbOutlined, FallOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import { useTypedTranslation } from 'src/common/utils'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { OrganizationLink } from '../OrganizationLink'
import { ACTION_PLANS_ROUTE, ACTION_TEMPLATES_ROUTE, REDUCE_MENU, TRAJECTORY_ROUTE } from '../routes'
import { usePermissions } from './index'
import { type GetProp, Menu } from 'antd'

type useReduceMenuProps = {
  isReduceModuleEnabled: boolean
}

export const useReduceMenu = ({
  isReduceModuleEnabled
}: useReduceMenuProps): GetProp<typeof Menu, 'items'>[number] | null => {
  const { t } = useTypedTranslation()
  const { canReadAction, canReadTrajectoryAndForecast, canReadActionPlan, permissionsLoading } = usePermissions()
  const reduceDisplayForced = useOverridableFlag(UnleashFlag.ForceReduceDisplay)

  if (permissionsLoading) return null
  if (!reduceDisplayForced && !isReduceModuleEnabled) return null
  if (!canReadAction && !canReadTrajectoryAndForecast && !canReadActionPlan) return null

  const children: GetProp<typeof Menu, 'items'> = []

  if (canReadTrajectoryAndForecast) {
    children.push({
      key: `${REDUCE_MENU} ${TRAJECTORY_ROUTE}/*`,
      icon: <AimOutlined />,
      label: <OrganizationLink to="/trajectory">{t('menu.trajectory')}</OrganizationLink>
    })
  }

  if (canReadActionPlan) {
    children.push({
      key: `${REDUCE_MENU} ${ACTION_PLANS_ROUTE}/*`,
      icon: <UnorderedListOutlined />,
      label: <OrganizationLink to="/actionPlans">{t('menu.roadmap')}</OrganizationLink>
    })
  }

  if (canReadAction) {
    children.push({
      key: `${REDUCE_MENU} ${ACTION_TEMPLATES_ROUTE}/*`,
      icon: <BulbOutlined />,
      label: <OrganizationLink to="/actionTemplates">{t('menu.action_templates')}</OrganizationLink>
    })
  }

  return {
    key: REDUCE_MENU,
    icon: <FallOutlined />,
    label: t('menu.drive'),
    children
  }
}
