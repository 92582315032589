import { useCallback, useState } from 'react'

export function useModal<T>() {
  const [isVisible, setVisible] = useState(false)
  const [value, setValue] = useState<T>()

  const open = useCallback(
    (valueToShow?: T) => {
      setValue(valueToShow)
      setVisible(true)
    },
    [setValue, setVisible]
  )

  const close = useCallback(() => {
    setValue(undefined)
    setVisible(false)
  }, [setValue, setVisible])

  return {
    isVisible,
    open,
    close,
    value
  }
}
