import React, { type PropsWithChildren, useMemo } from 'react'
import { useSearchParams } from 'react-router'
import { SearchParamsContext } from 'src/common/providers/SearchParamsContext'

// The goal of this provider is to avoid to have a problem with the search params update when we have several
// components that updates the search params at the same moment, for example when we have multiple set on
// useFilterField in the same function
export const SearchParamsContextProvider = ({ children }: PropsWithChildren) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const contextValue = useMemo(() => ({ searchParams, setSearchParams }), [searchParams, setSearchParams])
  return <SearchParamsContext.Provider value={contextValue}>{children}</SearchParamsContext.Provider>
}
