import { useEffect, useRef } from 'react'
import type { InputRef } from 'antd'

type FocusElementProps = {
  trigger?: boolean
}

export function useFocusElementRef({ trigger = true }: FocusElementProps = {}) {
  const inputRef = useRef<InputRef>(null)
  useEffect(() => {
    if (inputRef.current && trigger) {
      setTimeout(() => {
        inputRef.current?.focus()
      }, 0)
    }
  }, [inputRef, trigger])

  return inputRef
}
