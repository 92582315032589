import { ApolloProvider } from '@apollo/client'
import { Navigate, Route, Routes } from 'react-router'
import { useAuthentifiedApolloClient } from 'src/common/apollo/apolloClient'
import { OrganizationsList } from 'src/app/multiOrganizations/OrganizationsList'
import { lazy, Suspense } from 'react'
import { CenteredLoader } from 'src/common/utils'
import { useImpersonateEmail } from 'src/common/hooks/useImpersonateEmail'

const Admin = lazy(() => import('src/modules/admin/Admin'))

export function MultiOrganizationsRoot() {
  const { impersonateEmail } = useImpersonateEmail()
  const apolloClient = useAuthentifiedApolloClient({ impersonateEmail })
  return (
    <ApolloProvider client={apolloClient}>
      <Routes>
        <Route index element={<OrganizationsList />} />
        <Route
          path="admin/:tabKey"
          element={
            <Suspense fallback={<CenteredLoader />}>
              <Admin />
            </Suspense>
          }
        />
        <Route path="admin" element={<Navigate to="/admin/organizations" replace />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ApolloProvider>
  )
}
