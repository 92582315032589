import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import { useEffect } from 'react'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { Language } from 'src/generated/graphql/types'
import { UserInfos } from 'src/models/UserInfos'
import { useOrganizationConfig } from 'src/provider/useOrganizationConfig'

declare global {
  const beamer_config: {
    product_id: string
    lazy: boolean
    onError: (data: any) => void
    callback: (data: any) => void
  }
  const Beamer: {
    init: () => void
    update: (params: {
      user_id?: string | null
      user_firstname?: string | null
      user_lastname?: string | null
      user_email?: string
      user_organization_id?: string
      user_organization_name?: string
      language: string
    }) => void
  }
}

function addBeamerScript() {
  const d = document
  const s = d.createElement('script')

  s.src = 'https://app.getbeamer.com/js/beamer-embed.js'
  ;(s as any).async = 1
  ;(s as any).defer = 1
  d.getElementsByTagName('head')[0]!.appendChild(s)
}

function alignRight() {
  const beamerLogo = document.getElementById('beamerSelector')
  if (beamerLogo) beamerLogo.style.setProperty('right', '20px', 'important')
}

function initializeBeamer({
  currentUser,
  organization,
  language,
  userAuthenticationEnabled
}: {
  currentUser: UserInfos
  organization: {
    id: string
    name: string
  }
  language: Language | null
  userAuthenticationEnabled: boolean
}) {
  if (!('Beamer' in window)) return
  const beamerLanguage = language ? (language === Language.En ? 'EN' : 'FR') : ''
  if (userAuthenticationEnabled) {
    Beamer.update({
      user_firstname: currentUser.firstName,
      user_lastname: currentUser.lastName,
      user_email: currentUser.email,
      user_id: currentUser.authUser.id.toString(),
      user_organization_id: organization.id,
      user_organization_name: organization.name,
      language: beamerLanguage
    })
  } else {
    Beamer.update({
      language: beamerLanguage
    })
  }
  Beamer.init()
}

addBeamerScript()
export const useBeamer = () => {
  const beamerEnabled = useOverridableFlag(UnleashFlag.BeamerEnabled) && process.env.NODE_ENV !== 'development'
  const beamerUserAuthenticationEnabled = useOverridableFlag(UnleashFlag.BeamerUserAuthenticationEnabled)
  const isCrispCTAHidden = useOverridableFlag(UnleashFlag.HideCrispCTA)
  const { currentUser, organization } = useOrganizationConfig()

  useEffect(() => {
    window.beamer_config = {
      product_id: import.meta.env.VITE_BEAMER_PRODUCT_ID,
      lazy: true, // Do not initialize it automatically
      onError: function (data: any) {
        console.error('Beamer error: ', data)
      },
      callback: function (data: any) {
        console.info('Beamer initialized: ', data)
      }
    }
    if (beamerEnabled) {
      initializeBeamer({
        currentUser,
        organization,
        language: currentUser.authUser.language,
        userAuthenticationEnabled: beamerUserAuthenticationEnabled
      })
      if (isCrispCTAHidden) alignRight()
    }
  }, [beamerEnabled])

  return null
}
