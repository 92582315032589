import React, { useMemo, useState } from 'react'
import { useMessageAPI } from 'src/provider/useMessageAPI'
import { v4 as uuid } from 'uuid'
import {
  type QueryExportCampaignQuestionnairesResponsesArgs,
  useCampaignQuestionnairesResponsesExportCompletionSubscription,
  useExportCampaignQuestionnairesResponsesLazyQuery
} from 'src/generated/graphql/types'
import { t } from 'i18next'

export const CampaignQuestionnairesResponsesExportContext = React.createContext<{
  ongoingExportRequestsIds: string[]
  isAnOngoingExportRequest: boolean
  sendCampaignQuestionnairesResponsesExportRequest: (
    input: Omit<
      QueryExportCampaignQuestionnairesResponsesArgs['exportCampaignQuestionnairesResponsesInput'],
      'frontendRequestId'
    >
  ) => Promise<void>
}>({
  ongoingExportRequestsIds: [],
  isAnOngoingExportRequest: false,
  sendCampaignQuestionnairesResponsesExportRequest: async () => {}
})

export function CampaignQuestionnairesResponsesExportProvider({ children }: { readonly children: React.ReactNode }) {
  const [ongoingExportRequestsIds, setOngoingExportRequestsIds] = useState<string[]>([])

  const messageAPI = useMessageAPI()

  const [downloadCampaignQuestionnairesResponses] = useExportCampaignQuestionnairesResponsesLazyQuery()

  useCampaignQuestionnairesResponsesExportCompletionSubscription({
    onData: ({ data }) => {
      if (data.data?.campaignQuestionnairesResponsesExportCompletion) {
        const exportRequestId = ongoingExportRequestsIds.find(
          ongoingExportRequestId =>
            ongoingExportRequestId === data.data?.campaignQuestionnairesResponsesExportCompletion.frontendRequestId
        )

        if (exportRequestId) {
          setOngoingExportRequestsIds(
            ongoingExportRequestsIds.filter(ongoingExportRequestId => ongoingExportRequestId !== exportRequestId)
          )

          if (data.data.campaignQuestionnairesResponsesExportCompletion.downloadUrl) {
            messageAPI.info(t('campaigns.details.responses_modal.export.ready'))

            window.open(data.data.campaignQuestionnairesResponsesExportCompletion.downloadUrl, '_blank')
          } else {
            messageAPI.error(data.data.campaignQuestionnairesResponsesExportCompletion.errorMessage)
          }
        }
      } else if (data.error) {
        messageAPI.error(data.error.message)
        setOngoingExportRequestsIds([])
      }
    }
  })

  async function sendCampaignQuestionnairesResponsesExportRequest(
    input: Omit<
      QueryExportCampaignQuestionnairesResponsesArgs['exportCampaignQuestionnairesResponsesInput'],
      'frontendRequestId'
    >
  ) {
    const exportRequestId = uuid()

    setOngoingExportRequestsIds([...ongoingExportRequestsIds, exportRequestId])

    await downloadCampaignQuestionnairesResponses({
      variables: {
        exportCampaignQuestionnairesResponsesInput: {
          ...input,
          frontendRequestId: exportRequestId
        }
      },
      onCompleted: () => {
        messageAPI.info(t('campaigns.details.responses_modal.export.started'))
      },
      onError: (error: Error) => {
        setOngoingExportRequestsIds(
          ongoingExportRequestsIds.filter(ongoingExportId => ongoingExportId !== exportRequestId)
        )
        messageAPI.error(error.message)
      }
    })
  }

  return (
    <CampaignQuestionnairesResponsesExportContext.Provider
      value={useMemo(() => {
        return {
          isAnOngoingExportRequest: ongoingExportRequestsIds.length > 0,
          ongoingExportRequestsIds,
          sendCampaignQuestionnairesResponsesExportRequest
        }
      }, [ongoingExportRequestsIds, sendCampaignQuestionnairesResponsesExportRequest])}
    >
      {children}
    </CampaignQuestionnairesResponsesExportContext.Provider>
  )
}
