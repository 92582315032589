import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

type ImpersonateEmailState = {
  isImpersonateUser: boolean
  impersonateEmail?: string
  setImpersonateEmail: (value?: string) => void
}

export const useImpersonateEmail = create(
  persist<ImpersonateEmailState>(
    set => ({
      isImpersonateUser: false,
      impersonateEmail: undefined,
      setImpersonateEmail: value => set({ impersonateEmail: value, isImpersonateUser: !!value })
    }),
    {
      name: 'impersonate-email-storage', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage) // (optional) by default, 'localStorage' is used
    }
  )
)
