import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { usePermissions } from 'src/navigation/_hooks'

export function useOrganizationTabsPermissions(): {
  displayOrganizationModule: boolean
  displayTab: {
    organizationTree: boolean
    siteTypes: boolean
    welcomePage: boolean
    languagesSettings: boolean
    customMenuItems: boolean
  }
} {
  const isCampaignsRelease = useOverridableFlag(UnleashFlag.ReleaseCampaigns)
  const isLanguageManagementEnabled = useOverridableFlag(UnleashFlag.LanguageManagement)

  const { canManageOrganizationHierarchy, canManageOrganizationSettings, canManageContributingEntityIndicatorData } =
    usePermissions()

  const displayTab = {
    /*
     *  the organizationTree tab is used for two reasons :
     * - manage the organization hierarchy (crud nodes/entities)
     * - add indicator data to a contributing entity
     */
    organizationTree: canManageOrganizationHierarchy || canManageContributingEntityIndicatorData,
    siteTypes: canManageOrganizationSettings && isCampaignsRelease,
    welcomePage: canManageOrganizationSettings,
    languagesSettings: isLanguageManagementEnabled && canManageOrganizationSettings,
    customMenuItems: canManageOrganizationSettings
  }
  return {
    displayOrganizationModule: Object.values(displayTab).some(Boolean),
    displayTab
  }
}
