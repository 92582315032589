import React from 'react'
import { defaultCategoryColor, generateURL } from 'src/common/utils'
import { CategoryLabel } from 'src/common/designSystem/labels/category/CategoryLabel'

export type Size = 'normal' | 'small'
type Config = {
  imageSize: string
  width: string
}
const CONFIG_BY_SIZE: Record<Size, Config> = {
  small: {
    imageSize: '16px',
    width: '24px'
  },
  normal: {
    imageSize: '24px',
    width: '36px'
  }
}

type CategoryDisplayProps = {
  readonly category: CategoryLabel
  readonly size: Size
}

export function CategoryDisplay({ category, size }: CategoryDisplayProps) {
  const config = CONFIG_BY_SIZE[size]
  return (
    <div className="flex space-x-1" style={{ minWidth: config.width }}>
      <div
        style={{ backgroundColor: category.color ?? defaultCategoryColor, width: '8px', height: 'auto' }}
        className="rounded"
      />
      <img
        src={generateURL({ iconName: category.iconName ?? '' })}
        alt=""
        width={config.imageSize}
        height={config.imageSize}
      />
    </div>
  )
}
