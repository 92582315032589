import { useOverridableFlag } from 'src/services/unleash/useOverridableFlag'
import { useEffect, useState } from 'react'
import { CenteredLoader } from 'src/common/utils'
import { UnleashFlag } from 'src/common/utils/UnleashFlags'
import { useCountCurrentUserActiveCampaignTasksQuery } from 'src/generated/graphql/types'
import { useOrganizationConfig } from 'src/provider'
import { OrganizationNavigate } from 'src/navigation/OrganizationNavigate'

export const DefaultPageNavigate = () => {
  const {
    organization: { hasWelcomePageEnabled }
  } = useOrganizationConfig()
  const featureCampaignsEnabled = useOverridableFlag(UnleashFlag.ReleaseCampaigns)
  const [nbTodoTasks, setNbTodoTasks] = useState<number | null>(null)
  const [isDataReady, setIsDataReady] = useState(false)

  const { loading, data } = useCountCurrentUserActiveCampaignTasksQuery({
    skip: !featureCampaignsEnabled
  })

  useEffect(() => {
    if (!loading && data) {
      setNbTodoTasks(data.countCurrentUserActiveCampaignTasks)
      setIsDataReady(true)
    }
    if (!featureCampaignsEnabled) {
      setIsDataReady(true)
    }
  }, [loading, data])

  if (!isDataReady) {
    return <CenteredLoader />
  }
  if (hasWelcomePageEnabled) {
    return <OrganizationNavigate to="welcome" />
  }
  if (nbTodoTasks && nbTodoTasks > 0) {
    return <OrganizationNavigate to="mytasks?status=TODO" />
  }
  return <OrganizationNavigate to="analyses/dashboards/list" />
}
