import { InfoCircleOutlined } from '@ant-design/icons'
import React, { type PropsWithChildren } from 'react'

export function StickyMessage({ children }: PropsWithChildren) {
  return (
    <div
      className={
        'bg-blue-50 fixed top-0 md:left-[250px] left-[60px] right-0 z-50 h-8 flex flex-row gap-2 pl-4 items-center justify-center'
      }
    >
      <InfoCircleOutlined className={'text-info'} />
      {children}
    </div>
  )
}
