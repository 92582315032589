import React, { Component, type ErrorInfo, type PropsWithChildren, type ReactNode } from 'react'
import { addDatadogError } from '../services/rum'
import { WarningOutlined } from '@ant-design/icons'
import { Trans } from 'react-i18next'
import { isDevEnvironment } from 'src/common/utils/environment'

type State = {
  hasError: boolean
}

class ErrorCatcher extends Component<PropsWithChildren, State> {
  constructor(props: { children: ReactNode }) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    addDatadogError(error, { errorType: 'front', ...errorInfo })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex flex-col items-center mt-24 font-Inter gap-4">
          <WarningOutlined style={{ fontSize: '42px' }} />
          <Trans i18nKey="error_screen.message">Something went wrong.</Trans>
          <a href="/">
            <Trans i18nKey="error_screen.link">Go back home</Trans>
          </a>
        </div>
      )
    }
    return this.props.children
  }
}

export function ErrorScreen({ children }: PropsWithChildren) {
  if (isDevEnvironment()) return children
  return <ErrorCatcher>{children}</ErrorCatcher>
}
