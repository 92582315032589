import mixpanel from 'mixpanel-browser'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router'
import { isProductionEnvironment } from 'src/common/utils/environment'
import './common/utils/i18n'
import './index.css'
import 'src/services/rum'
import { Root } from 'src/app/Root'
import { colors } from 'src/app/theme/traace'
/**
 * DO NOT DELETE THIS IMPORT. It loads polyfill for dinosaurs.
 */
import './polyfills'
import { MessageAPIProvider } from 'src/provider/MessageProvider'
import { AntConfigProvider } from 'src/app/theme/AntConfigProvider'

const mixpanelToken = import.meta.env.VITE_MIXPANEL_TOKEN
if (mixpanelToken) {
  mixpanel.init(mixpanelToken, { debug: !isProductionEnvironment() })
}

const router = createBrowserRouter([{ path: '*', element: <Root /> }])

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <AntConfigProvider colors={colors}>
      <MessageAPIProvider>
        <RouterProvider router={router} />
      </MessageAPIProvider>
    </AntConfigProvider>
  </React.StrictMode>
)
